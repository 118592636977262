<template>
  <div class="offer-filters">
    <div class="offer-filters__container p-2">
      <div class="offer-filters__tabs mb-3">
        <button
          @click="activeTab = 1"
          class="offer-filters__tab mr-2"
          :class="{'offer-filters__tab-active': activeTab === 1}"
        >
          Filters
        </button>
        <button
          @click="activeTab = 2"
          class="offer-filters__tab"
          :class="{'offer-filters__tab-active': activeTab === 2}"
        >
          Settings
        </button>
      </div>
      <div class="offer-filters__tabs-content">
        <div v-if="activeTab === 1">
          <VSearch
            :initialValue="search"
            placeholder="Search"
            class="xs"
            @input="search = $event"
            @clear="clearSearch"
          />
          <div class="offer-filters__row mt-2 mb-2">
            <div class="offer-filters__col">
              <div class="offer-filters__block-select">
                <div
                  class="offer-filters__block-item"
                  @click="activeOption = 1"
                  :class="{'offer-filters__option-active': activeTab === 1}"
                >
                  Offers
                </div>
              </div>
            </div>
            <div class="offer-filters__col">
              <div class="offer-filters__block-select" v-if="activeOption === 1">
                <div
                  class="offer-filters__block-item"
                  v-for="item in _formattedListOffers"
                  :key="item.id"
                >
                  <label class="offer-filters__block-label d-flex align-items-center">
                    <input
                      type="checkbox"
                      class="mr-2"
                      v-model="checkedItems"
                      :value="item"
                      @click="selectOffer(item)"
                    >
                    {{ item.label }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="offer-filters__row mt-2 mb-2">
            <v-button
              type="blue"
              class="btn-ml"
              @click="removeFilters"
            >
              Remove all filters
            </v-button>
          </div>
        </div>
        <div v-if="activeTab === 2">
          <div class="select mb-2">
            <div class="mb-1">TimeZone</div>
            <VSelect
              size="sm"
              get-type="object"
              :options="timezones"
              :value="settings.timezone.id"
              @input="setSettingsFromSelect($event, 'timezone')"
            />
          </div>
          <div class="select" v-if="showCurrencies">
            <div class="mb-1">Currency</div>
            <VSelect
              size="sm"
              get-type="object"
              :options="currencies"
              :value="settings.currency.id"
              @input="setSettingsFromSelect($event, 'currency')"
            />
          </div>
        </div>
      </div>

      <div class="offer-filters__btns">
        <v-button type="orange" class="btn-xl mr-2" @click="save">Save</v-button>
        <v-button
          type="orange-outline"
          class="btn-xl"
          @click="cancel">
          Cancel
        </v-button>
      </div>
    </div>
  </div>

</template>

<script>
import VSearch from "@/js/components/common/VSearch";
import VSelect from "@/js/components/common/VSelect";

export default {
  name: "OfferFilters",
  components: {
    VSearch,
    VSelect
  },
  props: {
    defaultSelectedItems: Array,
    value: Array,
    timezones: Array,
    currencies: Array,
    showCurrencies: {
      type: Boolean,
      default: true
    },
    timezoneId: Number,
    currencyId: String,
  },
  data() {
    return {
      search: '',
      activeTab: 1,
      activeOption: 1,
      defaultSelected: this.defaultSelectedItems,
      checkedItems: this.value,
      settings: {
        timezone: {
          id: this.timezoneId,
          label: this.$store.getters['meta/timezoneLabelById'](this.timezoneId)
        },
        currency: {
          id: this.currencyId,
          label: this.$store.getters['meta/currencyLabelById'](this.currencyId)
        },
      }
    };
  },
  computed: {
    _formattedListOffers() {
      return this.defaultSelected.filter(col => {
        return col.label.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    setSettingsFromSelect(option, key) {
      this.settings[key] = {
        id: option.id,
        label: option.label,
      };
    },
    selectOffer(option) {
      this.defaultSelected.includes(option)
        ? this.checkedItems = this.checkedItems.filter(item => item !== option)
        : this.checkedItems.push(option);
    },
    save() {
      this.$emit('input', this.checkedItems);
      this.$emit('saveSettingsFilters', this.settings);
      this.$store.commit('modalTableColumns/TOGGLE_OFFER_FILTERS', false);
    },
    cancel() {
      this.clearSearch();
      this.checkedItems = [];
      this.$store.commit('modalTableColumns/TOGGLE_OFFER_FILTERS', false);
    },
    removeFilters() {
      this.clearSearch();
      this.checkedItems = [];
    },
    clearSearch() {
      this.search = '';
    }
  }
};
</script>

<style lang="scss" scoped>

.offer-filters {
  &__container {
    width: 100%;
    background-color: var(--white-main);
    border-radius: 8px;
  }

  &__tab {
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }

  &__tab-active {
    color: var(--blue-main);
    position: relative;

    &:before {
      content: '';
      display: block;
      background-color: var(--blue-main);
      height: 2px;
      width: 100%;
      position: absolute;
      bottom: -8px;
      border-radius: 2px;
    }
  }

  &__tabs-content {
    min-height: 360px;
  }


  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__col {
    width: 48%;
  }

  &__block-select {
    background: #FFFFFF;
    border: 1px solid var(--grey-light-1);
    border-radius: 8px;
    height: 224px;
    overflow-y: scroll;
  }

  &__block-icon {
    display: none;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__block-item {
    height: 32px;
    display: grid;
    align-content: center;
    padding: 0 8px;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: var(--blue-light);

      .v-draggable__block-icon {
        display: block;
      }
    }
  }

  &__block-label {
    height: 100%;
  }


  &__option-active {
    background-color: var(--blue-light);
  }


  &__btns {
    display: flex;
    justify-content: flex-end;
  }

  .error-text {
    display: none;
    color: var(--red-main);
    text-align: right;
  }

  .error {
    display: block;
  }
}

</style>