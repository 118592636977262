import moment from "moment";

export default {
  computed: {
    _currencySign() {
      return this.$store.getters['auth/currencySign'];
    },
    _tableHeadersForExport() {
      return this._getSettings.selectedColumns.map(c => ({
        slug: this.defaultColumns[c].slug,
        name: this.defaultColumns[c].name,
      }))
    },
  },
  methods: {
    showWithCurrencySign(amount) {
      if (amount >= 0) {
        return this._currencySign + amount;
      } else {
        return '-' + this._currencySign + Math.abs(amount);
      }
    },
    timestampToDate(ts) {
      return moment(ts * 1000).format('MM/DD/YYYY')
    },

    exportTable() {
      const csvContent = "data:text/csv;charset=utf-8,"
        + this._tableHeadersForExport.map(c => c.name).join(', ') + "\n"
        + this._tableData.map(row => {
          const cols = [];

          this._tableHeadersForExport.forEach((col) => {
            cols.push(row[col.slug]);
          });
          
          return cols.join(', ')
        }).join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${this.$options.name}s_from_${this.query.from}_till_${this.query.to}.csv`);
      document.body.appendChild(link); // Required for FF
      link.click();
    },
  }
}