<template>
  <div class="reports__main">
    <div class="reports__main-title text-semibold mb-3">Apply specific requests</div>
    <div>
      <div class="reports__performance">
        <div class="reports__performance-filter">
          <VDatePicker v-model="dateRange" :limit-date-range="true" v-on:click.stop.prevent.native />
          <v-button
              type="blue"
              class="reports__performance-filter-button"
              icon="filter"
              icon-position="right"
              @click="$store.commit('modalTableColumns/TOGGLE_OFFER_FILTERS', true)"
          >
            Filters
          </v-button>
          <v-button
              type="orange"
              class="reports__performance-filter-button"
              icon="play"
              @click="generateReport"
          >
            Run report
          </v-button>
        </div>
        <div class="mb-4">
          <VAlert>
            Please note, that for this type of report the max period length is 14 days
          </VAlert>
        </div>
        <div class="d-flex mb-2">
          <VChips
              v-for="item in selectedOffers"
              :key="item.id"
              :id="item.id"
              :label="item.label"
              class="mr-1"
              @delete="deleteChipOffer"
          />
        </div>
        <VMask v-if="$store.state.modalTableColumns.toggleOfferFilters">
          <div class="reports__block-wrap">
            <OfferFilters
                :defaultSelectedItems="_listFilterOffers"
                v-model="selectedOffers"
                :timezones="_timezones"
                :currencies="_currencies"
                :timezone-id="query.timezone_id"
                :currency-id="query.currency_id"
                @saveSettingsFilters="saveSettingsFilters"
                :showCurrencies="false"
            />
          </div>
        </VMask>
        <keep-alive>
          <VMask v-if="$store.state.modalTableColumns.toggleTableColumns">
            <div class="reports__block-wrap">
              <TableColumns
                  title="Table columns"
                  :default-columns="defaultColumns"
                  :default-selected-columns="_headCols"
                  :selected-columns-ids="_getSettings.selectedColumns"
                  searchBy="selectedColumns"
                  @save="setSelectedColumns"
              />
            </div>
          </VMask>
        </keep-alive>
      </div>
    </div>

    <VLoader v-if="_fetchingStatus === requestStatus.PENDING" />
    <div v-else-if="_fetchingStatus === requestStatus.SUCCESS && _dataWasLoaded">
      <div
          v-if="dateRange[0] !== null"
          class="pb-5 text-semibold report reports__main-title"
          :class="{'mt-3': selectedOffers.length}"
      >
        Offer report >
        <span v-if="dateRange.length">{{ dateRange[0] }} to {{ dateRange[1] }}</span>
        based on UTC
      </div>
      <div class="mt-5 mb-3 performance">
        <v-button
            type="blue-filled"
            class="btn-xl"
            icon="columns"
            @click="$store.commit('modalTableColumns/TOGGLE_TABLE_COLUMNS', true)"
        >
          <span class="text-semibold">Columns</span>
        </v-button>
        <v-button
            type="small-blue"
            class="btn-xl"
            icon="csv"
            @click="exportTable"
            v-show="_tableData.length"
        >
          Export
        </v-button>
      </div>

      <VTable
          :headCols="_headCols"
          :bodyRows="_tableData"
          @onSortByCol="onSortByCol"
      />
    </div>
  </div>
</template>

<script>
import VAlert from '@/js/components/common/VAlert';
import VChips from '@/js/components/common/VChips';
import VTable from "@/js/components/common/VTable";
import TableColumns from "@/js/components/pages/offers/popup/TableColumns";
import VMask from "@/js/components/common/VMask";
import VDatePicker from "@/js/components/common/VDatePicker";
import moment from "moment";
import tableMixin from "@/js/mixins/tableMixin";
import reportsMixin from "@/js/mixins/reportsMixin";
import OfferFilters from "@/js/components/pages/reports/popup/OfferFilters";
import { requestStatus } from '@/js/utils/constants';
import { defaultColumnsForClick } from '@/js/utils/table/defaultColumns';

export default {
  name: "Click",
  components: {
    VAlert,
    VChips,
    OfferFilters,
    VTable,
    TableColumns,
    VMask,
    VDatePicker
  },
  mixins: [tableMixin, reportsMixin],
  data() {
    return {
      requestStatus,
      defaultColumns: defaultColumnsForClick.map((col) => ({
        ...col,
        name: col.name.replace('{currencySign}', this.$store.getters['auth/currencySign']),
      })),
      dateRange: [],
      selectedOffers: [],
      query: {
        "query": {
          "filters": []
        },
        "from": moment().format('YYYY-MM-DD'),
        "to": moment().format('YYYY-MM-DD'),
        "timezone_id": this.$store.state.auth.timezone_id,
        "currency_id": this.$store.state.auth.currency_id,
        "autoRun": "false"
      },
      filter: {
        sort: 'click_date',
        order: 'asc'
      }
    };
  },
  computed: {
    _getSettings() {
      return this.$store.getters['appSessionSettings/getClickSettings'];
    },
    _headCols() {
      return this.defaultColumns
        .filter(column => this._getSettings.selectedColumns.includes(column.id))
        .sort((a, b) => a.id - b.id);
    },
    _fetchingStatus() {
      return this.$store.state.reports.fetchingClicksStatus;
    },
    _timezones() {
      return this.$store.getters["meta/timezoneList"];
    },
    _currencies() {
      return this.$store.getters["meta/translatedCurrencies"];
    },
    _selectedOffers() {
      return this.selectedOffers.map(obj => ({
        ...obj,
        filter_id_value: `${obj.id}`,
        resource_type: "offer"
      }));
    },
    _listFilterOffers() {
      return this.$store.state.reports.filterOffers || [];
    },
    _dataWasLoaded() {
      return this.$store.state.reports.click?.clicks?.length || this.$store.state.reports.click?.clicks;
    },
    _tableData() {
      return this.$store.state.reports.click.clicks;
    },
  },
  methods: {
    saveSettingsFilters(e) {
      this.query.timezone_id = e.timezone.id;
      this.query.currency_id = e.currency.id;
    },
    deleteChipOffer(offerId) {
      this.selectedOffers = this.selectedOffers.filter(item => item.id !== offerId);
    },
    async generateReport() {
      this.query.from = this.dateRange[0] || moment().format('YYYY-MM-DD');
      this.query.to = this.dateRange[1] || moment().format('YYYY-MM-DD');
      this.query.query.filters = this._selectedOffers;
      this.query.order_field = this.filter.sort;
      this.query.order_direction = this.filter.order;

      await this.$store.dispatch('reports/getClicks', this.query);
    },
    onSortByCol(order) {
      this._headCols.forEach(item => item.orderBy = 'def');
      const current = this._headCols.find(item => item.id === order.id);
      this.filter.order = order.orderBy;
      this.$set(current, 'orderBy', order.orderBy);
      this.filter.sort = order.sort;
      this.generateReport();
    },
    setSelectedColumns(selectedColumns) {
      this.$store.commit('appSessionSettings/SET_SELECTED_COLUMNS', {
        pageKey: 'click',
        selectedColumns,
      });
      this.$store.dispatch('appSessionSettings/updateSettings');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>