<template>
  <div class="reports">
    <h2 class="reports__title title-page">Reports</h2>
    <div class="mb-4">
      <VTabs :tabs="tabs" :selected-tab="selectedTab" @click="handleTabClick" />
    </div>
    <keep-alive>
      <component :is="selectedTab"></component>
    </keep-alive>
  </div>
</template>
<script>

import Offer from "@/js/components/pages/reports/Offer";
import Daily from "@/js/components/pages/reports/Daily";
import Conversion from "@/js/components/pages/reports/Conversion";
import Click from "@/js/components/pages/reports/Click";
import VTabs from "@/js/components/common/VTabs";

export default {
  name: "Reports",
  components: {Click, Conversion, Daily, Offer, VTabs},
  data() {
    return {
      tabs: [
        {title: 'offer', component: 'offer'},
        {title: 'daily', component: 'daily'},
        {title: 'conversion', component: 'conversion'},
        {title: 'click', component: 'click'}
      ],
      selectedTab: 'offer',
    }
  },
  methods: {
    handleTabClick(tab) {
      this.selectedTab = tab.component;
    }
  },
  destroyed() {
    this.$store.commit('reports/SET_OFFERS', null);
    this.$store.commit('reports/SET_DAILIES', null);
    this.$store.commit('reports/SET_CONVERSIONS', null);
    this.$store.commit('reports/SET_CLICKS', null);
  }
}
</script>

<style lang="scss">
.reports {

  &__main-title {
    font-size: 18px;
  }

  .summary {
    padding-bottom: 30px;
    border-bottom: 1px solid var(--grey-light-1);

    &__wrap {
      max-width: 836px;
    }

    .table {
      &__th {
        font-size: 12px;
        border-bottom: none;
      }
    }

  }

  &__main {
    width: 100%;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: var(--main-box-shadow);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
    padding-right: 100px;
    align-items: flex-end;
  }

  &__filter-name {
    margin-bottom: 8px;
  }

  &__performance-filter {
    display: grid;
    grid-template-columns: auto minmax(110px, auto) auto;
    grid-column-gap: 16px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 36px;

    &-button {
      height: 30px;
    }
  }

  .row-item {
    max-width: 200px;
    height: 20px;
    display: block;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  &__block-wrap {
    max-width: 480px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  .table {
    padding-bottom: 15px;
    display: grid;
    grid-gap: 8px;
    //grid-template-columns: repeat(5, auto);
    grid-template-columns: repeat(auto-fill, auto);
    grid-auto-flow: column;
    grid-auto-columns: auto;
    overflow-x: auto;

    &__th {
      padding-bottom: 8px;
      border-bottom: 1px solid var(--blue-light);
    }

  }
}

.performance {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>