<template>
  <div
      class="v-chips"
      @click="$emit('delete', id)"
  >
    <icon-delete-chip class="mr-1" />
    {{ label }}
  </div>
</template>

<script>
import IconDeleteChip from '@/js/components/icons/IconDeleteChip';

export default {
  name: "VChips",
  components: { IconDeleteChip },
  props: {
    id: Number,
    label: String,
  }
};
</script>

<style lang="scss" scoped>
.v-chips {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 12px;
  color: var(--white-main);
  background: var(--blue-main);
  border: 1px solid var(--blue-main);
  border-radius: 20px;
  cursor: pointer;
}
</style>