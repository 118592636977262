<template>
  <div class="reports__main">
    <div class="reports__main-title text-semibold mb-3">Apply specific requests</div>
    <div>
      <div class="reports__performance">
        <div class="reports__performance-filter">
          <VDatePicker v-model="dateRange" v-on:click.stop.prevent.native />
          <v-button
            type="blue"
            class="reports__performance-filter-button"
            icon="filter"
            icon-position="right"
            @click="$store.commit('modalTableColumns/TOGGLE_OFFER_FILTERS', true)"
          >
            Filters
          </v-button>
          <v-button
            type="orange"
            class="reports__performance-filter-button"
            icon="play"
            @click="generateReport"
          >
            Run report
          </v-button>
        </div>
        <div class="d-flex mb-2">
          <VChips
            v-for="item in selectedOffers"
            :key="item.id"
            :id="item.id"
            :label="item.label"
            class="mr-1"
            @delete="deleteChipOffer"
          />
        </div>
        <VMask v-if="$store.state.modalTableColumns.toggleOfferFilters">
          <div class="reports__block-wrap">
            <OfferFilters
              :defaultSelectedItems="_listFilterOffers"
              v-model="selectedOffers"
              :timezones="_timezones"
              :currencies="_currencies"
              :timezone-id="query.timezone_id"
              :currency-id="query.currency_id"
              @saveSettingsFilters="saveSettingsFilters"
            />
          </div>
        </VMask>
        <keep-alive>
          <VMask v-if="$store.state.modalTableColumns.toggleTableColumns">
            <div class="reports__block-wrap">
              <TableColumns
                title="Table columns"
                :default-columns="defaultColumns"
                :default-selected-columns="_headCols"
                :selected-columns-ids="_getSettings.selectedColumns"
                searchBy="selectedColumns"
                @save="setSelectedColumns"
              />
            </div>
          </VMask>
        </keep-alive>
      </div>
    </div>

    <VLoader v-if="_fetchingStatus === requestStatus.PENDING" />
    <div v-else-if="_fetchingStatus === requestStatus.SUCCESS && _dataWasLoaded">
      <div
        v-if="dateRange[0] !== null"
        class="mb-5 text-semibold reports__main-title"
        :class="{'mt-3': selectedOffers.length}"
      >
        Offer report >
        <span v-if="dateRange.length">{{ dateRange[0] }} to {{ dateRange[1] }}</span>
        based on UTC
      </div>
      <v-collapse :showLine="true" :showIcon="false">
        <template v-slot:header>
          <div class="offer-details__block-header text-semibold mr-6">Summary</div>
        </template>
        <template v-slot:body>
          <div class="summary mt-2">
            <div class="summary__wrap">
              <div class="table">
                <div class="table__tr" v-for="(row, index) in _summaryData" :key="index">
                  <div class="table__th text-semibold color-grey-main summary__th">{{ index }}</div>
                  <div class="table__td text-semibold color-blue-main">{{ row }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-collapse>
      <div class="mt-7 mb-3 performance">
        <v-button
          type="blue-filled"
          class="btn-xl"
          icon="columns"
          @click="$store.commit('modalTableColumns/TOGGLE_TABLE_COLUMNS', true)"
        >
          <span class="text-semibold">Columns</span>
        </v-button>
        <v-button
          type="small-blue"
          class="btn-xl"
          icon="csv"
          @click="exportTable"
          v-show="_tableData.length"
        >
          Export
        </v-button>
      </div>

      <VTable
        :headCols="_headCols"
        :bodyRows="_tableData"
        @onSortByCol="onSortByCol"
      >
        <template #col-0="{ row }">
          <router-link :to="'/offers/'+row.offer_id" class="table-link">
            {{ row.name }}
          </router-link>
        </template>
        <template #col-3="{ row }">
          <span class="color-blue-main">{{ row.total_click }}</span>
        </template>
        <template #col-5="{ row }">
          <span class="color-blue-main">{{ row.cv }}</span>
        </template>
      </VTable>
    </div>
  </div>
</template>

<script>
import VChips from '@/js/components/common/VChips';
import VTable from "@/js/components/common/VTable";
import TableColumns from "@/js/components/pages/offers/popup/TableColumns";
import VMask from "@/js/components/common/VMask";
import VDatePicker from "@/js/components/common/VDatePicker";
import moment from "moment";
import VCollapse from "@/js/components/common/VCollapse";
import tableMixin from "@/js/mixins/tableMixin";
import OfferFilters from "@/js/components/pages/reports/popup/OfferFilters";
import reportsMixin from "@/js/mixins/reportsMixin";
import { defaultColumnsForOffer } from '@/js/utils/table/defaultColumns';
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "Offer",
  components: {
    VChips,
    OfferFilters,
    VCollapse,
    VTable,
    TableColumns,
    VMask,
    VDatePicker
  },
  mixins: [tableMixin, reportsMixin],
  data() {
    return {
      requestStatus,
      currencySign: '€',
      defaultColumns: defaultColumnsForOffer.map((col) => ({
        ...col,
        name: col.name.replace('{currencySign}', this.$store.getters['auth/currencySign']),
      })),
      dateRange: [],
      selectedOffers: [],
      query: {
        query: {
          filters: []
        },
        columns: [
          {
            column: 'offer',
            translate_key: 'offer',
            data: 'offer.label',
            category_translate_key: 'offer',
            label: 'Offer',
            category: 'Offer'
          }
        ],
        from: moment().format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        currency_id: this.$store.state.auth.currency_id,
        timezone_id: this.$store.state.auth.timezone_id,
        autoRun: false,
      },
      filter: {
        order: 'name',
        sort: 'asc',
      },
    };
  },
  mounted() {
    this.setPrefilledFilterFromOfferDetails();
    this.getFilterOffers();
  },
  computed: {
    _getSettings() {
      return this.$store.getters['appSessionSettings/getOfferSettings'];
    },
    _headCols() {
      return this.defaultColumns
        .filter(column => this._getSettings.selectedColumns.includes(column.id))
        .sort((a, b) => a.id - b.id);
    },
    _fetchingStatus() {
      return this.$store.state.reports.fetchingOffersStatus;
    },
    _timezones() {
      return this.$store.getters["meta/timezoneList"];
    },
    _currencies() {
      return this.$store.getters["meta/translatedCurrencies"];
    },
    _listFilterOffers() {
      return this.$store.state.reports.filterOffers || [];
    },
    _dataWasLoaded() {
      return !(typeof this.$store.state.reports.offers.incomplete_results === 'undefined' && typeof this.$store.state.reports.offers.incomplete_results);
    },
    _summaryData() {
      const data = this.$store.state.reports.offers.summary;

      return {
        'CLICKS': data.gross_click,
        'UNIQUE_CLICKS': data.total_click,
        'TOTAL_CV': data.total_cv,
        'CTR': data.ctr + '%',
        'CVR': data.cvr + '%',
        'EPC': this.showWithCurrencySign(data.epc),
        'CPA': this.showWithCurrencySign(data.cpa),
        'PAYOUT': this.showWithCurrencySign(data.payout),
      };
    },
    _tableData() {
      return this.$store.state.reports.offers?.table;
    },
    _selectedOffers() {
      return this.selectedOffers.map(obj => ({
        ...obj,
        filter_id_value: `${obj.id}`,
        resource_type: "offer"
      }));
    }
  },
  methods: {
    setPrefilledFilterFromOfferDetails() {
      const { offerData } = this.$route.params;
      if (offerData) {
        this.dateRange = offerData.dateRange;
        this.selectedOffers = offerData.selectedOffers;
      }
    },
    saveSettingsFilters(e) {
      this.query.timezone_id = e.timezone.id;
      this.query.currency_id = e.currency.id;
    },
    async getFilterOffers() {
      await this.$store.dispatch('reports/getFilterOffers');
    },
    deleteChipOffer(offerId) {
      this.selectedOffers = this.selectedOffers.filter(item => item.id !== offerId);
    },
    async generateReport() {
      this.query.from = this.dateRange[0] || moment().format('YYYY-MM-DD');
      this.query.to = this.dateRange[1] || moment().format('YYYY-MM-DD');
      this.query.query.filters = this._selectedOffers;
      this.query.order_field = this.filter.sort;
      this.query.order_direction = this.filter.order;

      await this.$store.dispatch('reports/getOffers', {
        query: this.query,
      });
    },
    onSortByCol(order) {
      this._headCols.forEach(item => item.orderBy = 'def');
      const current = this._headCols.find(item => item.id === order.id);
      this.filter.order = order.orderBy;
      this.$set(current, 'orderBy', order.orderBy);
      this.filter.sort = order.sort;
      this.generateReport();
    },
    setSelectedColumns(selectedColumns) {
      this.$store.commit('appSessionSettings/SET_SELECTED_COLUMNS', {
        pageKey: 'offer',
        selectedColumns,
      });
      this.$store.dispatch('appSessionSettings/updateSettings');
    },
  },
};
</script>

<style lang="scss" scoped>
.table-link {
  color: var(--blue-main);
  text-decoration: none;
}
</style>
